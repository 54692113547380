import React from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Redirect } from "react-router-dom";
import { Alert, Button, Col, Container, Form, Input, Row, Spinner } from "reactstrap";

function Register({ type, successMsg }) {
  const urlParams = new URLSearchParams(window.location.search);
  const utm_source = urlParams.get("utm_source");
  const utm_medium = urlParams.get("utm_medium");
  const utm_campaign = urlParams.get("utm_campaign");

  const [details, setDetails] = React.useState({
    type,
    utm_source,
    utm_medium,
    utm_campaign,
    first_name: '',
    last_name: '',
    phone: '',
    email: '',
    company: '',
    job: '',
    marketing: 'No',
    consent: 'No',
  });
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [redirect, setRedirect] = React.useState(false);

  const onChangeDetails = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
  };

  const onSubmitForm = (e) => {
    e.preventDefault();
    setLoading(true);
    setError(false);
    setSuccess(false);
    // const reg = /[a-zA-Z0-9]*@[gmail|yahoo|hotmail]+\.[com|co.in]+/;
    // if (reg.test(details.email)) {
    //   setLoading(false);
    //   setError("Please enter your business email only");
    //   return null;
    // }
    // https://asia-east2-cmoconnext.cloudfunctions.net/api/khaleejawards
    fetch("https://api.khaleejtimesevents.com/khaleejtimes-awards/rg2025.php", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(details),
    })
      .then((res) => {
        console.log(res);
        if (res.ok) return res;
        else throw new Error(res);
      })
      .then(() => {
        setLoading(false);
        setRedirect(true);
      })
      .catch((err) => {
        setLoading(false);
        setError("Something went wrong please try again");
      });
  };
  let inputs = content;
  if (redirect) {
    return <Redirect to={`/thank-you`} />;
  }

  return (
    <div
      style={{
        paddingTop: "1rem",
        backgroundColor: "#ffdf97",
        overflow: "hidden",
      }}
    >
      <Container>
        <Row>
          <Col lg={12} className="align-self-center">
            <h1 className="section-title pt-4 text-center text-dark awards-title">
              <b>REGISTER</b>
            </h1>
          </Col>
          <Col className="mx-auto" lg={8}>
            <Container className="my-auto text-dark text-600 pb-5 awards-body">
              {type === "REGISTER" && <p></p>}
              <Form onSubmit={onSubmitForm} className="register-form">
                <Row className="justify-content-center text-dark">
                  {inputs.map((i) =>
                    i.name !== "phone" ? (
                      <Col lg={i.grid} className="my-2" key={i.name}>
                        <label>
                          {i.placeholder}
                          {!i.notrequired ? "*" : ""}
                        </label>
                        <Input
                          placeholder={i.placeholder}
                          type={i.type}
                          name={i.name}
                          onChange={onChangeDetails}
                          required={!i.notrequired}
                        />
                      </Col>
                    ) : (
                      <Col lg={6} className="my-2">
                        <label>Phone*</label>
                        <PhoneInput
                          country={"ae"}
                          inputProps={{
                            name: "phone",
                            required: true,
                            autoFocus: true,
                          }}
                          inputClass="w-100"
                          dropdownClass="text-dark"
                          value={details.phone}
                          onChange={(phone) => {
                            setDetails({ ...details, phone });
                          }}
                        />
                      </Col>
                    )
                  )}

                  <Col lg={12} className="text-left pt-2">
                    <p className="text-600" style={{ textTransform: "none" }}>
                      <input
                        type="checkbox"
                        name='marketing'
                        onChange={(e) => {
                          if (e.target.checked) {
                            setDetails({ ...details, marketing: 'Yes' });
                          } else {
                            setDetails({ ...details, marketing: 'No' });
                          }
                        }}
                      />
                      &nbsp;
                      I would like to receive marketing communications on products, services or events.
                    </p>
                  </Col>

                  <Col lg={12} className="text-left pt-2">
                    <p className="text-600" style={{ textTransform: "none" }}>
                      <input
                        type="checkbox"
                        name='consent'
                        required
                        onChange={(e) => {
                          if (e.target.checked) {
                            setDetails({ ...details, consent: 'Yes' });
                          } else {
                            setDetails({ ...details, consent: 'No' });
                          }
                        }}
                      />
                      &nbsp;
                      I provide consent to Galadari Printing and Publishing to process my personal data in line with the{" "}
                      <a href="https://www.khaleejtimes.com/privacy-notice" className="text-600" style={{ color: 'rgb(0, 0, 0)' }}>Privacy Notice</a>
                    </p>
                  </Col>

                  <Col lg={12}>
                    {loading ? (
                      <span>
                        <Spinner className="mt-3" color="warning" size="sm" />
                      </span>
                    ) : (
                      <Button
                        block
                        className="btn text-white text-700 mt-3"
                        style={{ backgroundColor: "#6648b4", border: 0 }}
                        size="lg"
                        type="submit"
                        disabled={loading || details.consent !== 'Yes'}
                      >
                        Submit
                      </Button>
                    )}
                  </Col>
                  <Col lg={12} className="text-left pt-2 d-none text-600 ">
                    <p style={{ textTransform: "none" }} className=" text-600 text-white">
                      By clicking download/submit you agree to receive further marketing information
                      from Khaleej Times Events on our series of live and digital events. Please{" "}
                      <a href="/privacy-policy" className="text-white text-600">
                        read our privacy policy here.
                      </a>
                    </p>
                  </Col>
                </Row>
              </Form>
            </Container>
            <CreateAlert success={success} error={error} />
          </Col>
        </Row>
      </Container>
      <Container className="my-5">
        <Row>
          {" "}
          <Col lg={12}>
            <div class="embed-responsive embed-responsive-16by9">
              <iframe
                class="embed-responsive-item"
                src="https://epaper.khaleejtimes.com/supplements-best-places-to-work-in-the-uae"
                allowfullscreen
                title="epaper"
              ></iframe>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

function CreateAlert({ success, error }) {
  const color = success ? "success" : "danger";
  const message = success ? success : error;
  if (!success && !error) {
    return "";
  } else {
    return (
      <Alert
        color={color}
        isOpen={true}
        fade={true}
        className="text-center"
        style={{ marginTop: "1rem" }}
      >
        {message}
      </Alert>
    );
  }
}

export default Register;

const content = [
  {
    id: 1,
    name: "first_name",
    type: "text",
    placeholder: "First Name",
    grid: 6,
  },
  { id: 2, name: "last_name", type: "text", placeholder: "Last Name", grid: 6 },
  { id: 2, name: "phone", type: "text", placeholder: "Phone", grid: 6 },
  {
    id: 3,
    name: "email",
    type: "text",
    placeholder: "Email",
    grid: 6,
  },
  {
    id: 8,
    name: "company",
    type: "text",
    placeholder: "Company",
    grid: 6,
  },
  {
    id: 2,
    name: "job",
    type: "text",
    placeholder: "Designation",
    grid: 6,
    notrequired: true,
  },
];
